@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$borderColor: #c2c2c2;
$lightgrey: #f8f8f8;
$white: #ffffff;
$primaryColor: #1b8989;
$gold: #dbc483;
$textcolor: #4e4e4e;
$breadcrumb-divider: quote(">");
$darkgrey: #333333;

.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">");
}

.dark-grey {
  color: #333333;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;

  h2 {
    font-size: 22px !important;
    text-transform: uppercase;
    font-weight: 600;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $lightgrey;
}

::-webkit-scrollbar-thumb {
  background: $primaryColor;
}

.banner {
  img {
    width: 100% !important;
    z-index: 1;
  }

  img.overlay-img {
    z-index: 2;
    position: absolute;
    left: 0%;
  }

  label {
    color: $white !important;
  }

  .banner-head {
    h1 {
      font-size: 64px !important;
      letter-spacing: 2px;
    }

    h1,
    h2 {
      color: $white;
      text-transform: uppercase;
    }

    h2 {
      font-size: 45px !important;
      font-weight: normal;
    }

    margin-bottom: 30px;
  }

  select,
  .multiSelectContainer,
  .searchWrapper {
    background-color: $white;
    height: 48px;
    border-radius: 5px;

    input {
      background-color: $white;
      height: 30px;
      border-radius: 5px;
    }
  }

  .position-absolute {
    padding: 35px;
    height: auto;
    min-height: 70px;
    width: 80%;
    z-index: 9;
    border-radius: 20px;

    .colored-row {
      padding: 15px 10px;

      .col-md-5,
      .col-md-2,
      .col {
        padding: 0 !important;
      }

      .col-md-2,
      .col {
        margin-left: 10px;
      }

      button {
        width: 48px;
        height: 48px;
        background-color: $primaryColor;
      }
    }

    .filter-first-row {
      button {
        background-color: transparent;
        color: $white;
        height: 37px;
        width: 60px;
        z-index: 9;
        border: 0 !important;
        text-transform: uppercase;

        &:hover,
        &:focus {
          color: $primaryColor;
          background-color: $white;
          text-decoration: underline;
          border: solid 0.5px $primaryColor;
          border-radius: 25px;
        }
      }

      .active-btn {
        color: $primaryColor;
        background-color: $white;
        text-decoration: underline;
        border: solid 0.5px $primaryColor;
        border-radius: 25px;
      }
    }
  }
}

.navbar {
  .login-cta {
    button {
      border-radius: 20px;
      border: 1px solid $borderColor;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(175, 166, 166, 0.25);
      width: 85px;
      height: 41px;
      font-weight: 500;
      height: 41px;
      padding-bottom: 10px;

      &:hover {
        background: $primaryColor;
        color: $white;
      }
    }

    .buyer-img {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border: 2px solid $primaryColor;
      border-radius: 50px;
      cursor: pointer;
    }

    .notify-img {
      width: 18px;
      margin: 13px 30px;
      display: block;
      cursor: pointer;
    }
  }

  .main-nav {
    padding-right: var(--bs-gutter-x, -0.25rem) !important;
    padding-left: var(--bs-gutter-x, -0.25rem) !important;

    .navbar-nav {
      li {
        margin: auto;

        a {
          text-transform: uppercase;
          font-size: 16px;
          padding-right: 20px !important;
          padding-left: 20px !important;
          font-weight: 500;
        }

        .active {
          color: $primaryColor !important;
        }
      }
    }
  }
}

.carousel-section {
  h2 {
    text-transform: uppercase;
  }

  h6 {
    a {
      color: $primaryColor;
      text-decoration: none !important;
    }
  }

  // .broker-story {
  //     .owl-theme {
  //         .owl-item {
  //             min-width: 150px;

  //             img {
  //                 width: 130px !important;
  //                 margin: auto;
  //             }
  //         }
  //     }
  // }

  .damac,
  .secondary {
    .card {
      width: 100%;
      border-radius: 15px;
      box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.25));
      margin-bottom: 20px;
      background-color: $lightgrey;

      img {
        width: 100%;
      }
    }

    .card-new {
      width: 100%;
      border-radius: 15px;
      box-shadow: 4px 4px 4px 2px #bfbfbf40;
      margin-bottom: 10px;
      background-color: $lightgrey;

      img {
        width: 100%;
      }
    }
  }

  .damac {
    button {
      width: 100px;
      position: absolute;
      top: 22px;
      border-radius: 0 25px 25px 25px;
      border: 0px;
      background: $gold;
      color: $white;
      font-weight: 300;
      padding: 5px;
      font-size: 12px;
    }

    .card-text {
      font-size: 12px;
    }

    .d-flex {
      .card-text {
        text-transform: uppercase;
        margin-bottom: 0 !important;
      }
    }

    img {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  .secondary {
    .user-img {
      width: 60px !important;
    }

    .card-text {
      font-size: 12px;
      margin-top: 5px;
    }

    .card-body {
      .d-flex {
        p {
          font-size: 12px;
          margin-bottom: 0;
          line-height: 2.3;
        }

        .bed,
        .area {
          width: 23px;
          height: 23px;
        }
      }
    }
  }

  .promotion {
    .card {
      position: relative;
      box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.25));
      margin-bottom: 15px;

      img {
        border-radius: 0.25rem;
      }

      .carousel-overlay {
        position: absolute;
        opacity: 0.9;
        width: 45%;
        height: 100%;
      }

      .card-body {
        z-index: 9;
        position: absolute;
        bottom: 10px;
        font-size: 16px;
        line-height: 1.2;
        left: 15px;

        button {
          background: $gold;
          color: $white;
          border: 0;
          font-size: 11px;
          padding: 10px 10px;
          height: 30px;
          width: 69px;
          position: absolute;
          bottom: 80%;
          // margin-bottom: 15%;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }
}

.our-partners {
  .img-bg {
    background-color: #efefef;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 100%;
    min-height: 120px;
    display: flex;

    img {
      width: 125px !important;
      display: block;
      margin: auto;
    }
  }
}

.testimonials {
  .card {
    border: 0 !important;
    text-align: center;
    color: $textcolor;
    max-width: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    .card-text {
      text-transform: uppercase;
      font-size: 14px;
      margin-top: 30px;
      color: #606060;
    }

    img {
      width: 115px;
      border: 4px solid $primaryColor;
      border-radius: 60px;
    }

    h5 {
      font-weight: 600;
    }
  }
}

.appdownloads {
  margin-top: 60px;

  .mobile-img {
    width: 100%;
    max-width: 400px;
    margin: auto;
    display: block;
  }

  p {
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 30px;
    color: #606060;
  }

  .qr-img,
  .gplay-img,
  .appstore-img {
    display: block;
    margin: auto;
  }

  .qr-img {
    max-width: 200px;
  }

  .gplay-img,
  .appstore-img {
    max-width: 250px;
  }
}

.primary-text {
  color: $primaryColor !important;
  cursor: pointer;
}

.arrow-p {
  color: $primaryColor;
  margin-left: auto !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
  text-decoration: none !important;
}

.footer {
  font-size: 16px;
  // margin-top: 5%;
  background-color: $lightgrey;
  padding: 35px 5px;

  h3 {
    font-weight: normal;
    margin-bottom: 50px;
  }

  ul {
    li {
      border: 0 !important;
      padding: 2px;
      background-color: $lightgrey;

      &:first-child {
        text-decoration: underline;
        text-decoration-color: $primaryColor;
        text-underline-position: under;
        margin-bottom: 15px;
      }

      button {
        border-radius: 3px;
        background: $borderColor;
      }
    }
  }
}

@media (max-width: 1200px) {
  .mymeetings {
    .broker-img {
      top: -20px !important;
      left: 50px !important;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media (max-width: 768px) {
  .my-info {
    width: 100% !important;
  }

  .mymeetings {
    .broker-img {
      top: -20px !important;
      left: 50px !important;
    }
  }

  .selectbroker {
    width: 100% !important;

    .selection-list {
      .star {
        left: 40px !important;
        top: -15px !important;
      }

      button {
        width: 90px !important;
      }

      p {
        margin-left: 15px !important;
      }
    }
  }

  .broker-story {
    .owl-theme {
      .owl-item {
        img {
          width: 130px !important;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .carousel-section {
    .promotion {
      .card {
        .card-body {
          bottom: -10px !important;
          left: 0px !important;

          button {
            margin-bottom: 10% !important;
          }
        }
      }
    }
  }
}

.login-type-modal {
  top: 25%;

  .modal-header,
  .modal-body,
  .modal-footer {
    border: 0 !important;
  }

  .modal-header {
    h5 {
      margin-left: auto;
      margin-right: auto;

      span {
        border-bottom: 2px solid $primaryColor;
        font-weight: bold;
      }
    }
  }

  .modal-content {
    border-radius: 5px;
    border: 0 !important;
    max-width: 330px;
    width: 100%;
    margin: auto;
    padding: 15px;
  }

  .modal-body {
    img {
      width: 55px;
    }

    button {
      width: 100%;
      border-radius: 30px;
      border: 1.5px solid #1b8989;
      color: #1b8989;
      margin-top: 20px !important;

      &:hover {
        background: $primaryColor;
        color: $white !important;
      }
    }
  }

  .modal-footer {
    font-size: 11px;
    text-align: center;
  }
}

.red-txt {
  color: #ff5151;
}

.notify-modal {
  left: 12%;
}

.profile-modal {
  top: 5%;
  left: 32%;

  .modal-content {
    max-width: 350px;
    padding: 1px 20px;

    .modal-header {
      .buyer-img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border: 2px solid $primaryColor;
        border-radius: 50px;
        cursor: pointer;
      }
    }

    .modal-body {
      text-align: left !important;
      text-transform: uppercase;

      .icon {
        width: 18px;
      }
    }
  }
}

.main-login-modal {
  top: 25%;

  .modal-header,
  .modal-body,
  .modal-footer {
    border: 0 !important;
  }

  .modal-header {
    display: block;

    p {
      margin-bottom: 0 !important;
    }
  }

  .modal-content {
    border-radius: 5px;
    border: 0 !important;
    max-width: 330px;
    width: 100%;
    margin: auto;
    padding: 15px;
    font-size: 14px;
  }

  .modal-footer {
    display: block;

    button {
      width: 100%;
      background-color: #e5e5e5;
      box-shadow: 4px 4px 4px 0px rgba(118, 118, 118, 0.25);
      color: #7a7575;
      font-weight: 400;
      &:hover {
        background-color: #1b8989;
        color: white;
      }
    }
  }

  .modal-body {
    .input-boxes {
      .i-p {
        padding: 8px;
        border-right: 0 !important;
      }

      .d-flex {
        p {
          margin-left: auto;
          line-height: 2;
        }
      }

      .input-group-text {
        background-color: $white;
      }

      img {
        width: 13px;
      }
    }
  }
}

.sm-banner {
  .buy-sell {
    .btn {
      border: solid 1px $primaryColor;
      border-radius: 25px;
      height: 37px;
      width: 60px;
      z-index: 9;
      text-transform: uppercase;
    }

    .active-btn {
      color: $white;
      background-color: $primaryColor;
      text-decoration: underline;
    }
  }

  .form-check-label {
    color: $primaryColor !important;
  }
}

///// SEARCH BUTTON
.search-btn {
  background: $primaryColor;
  color: $white;
  height: 48px;

  img {
    width: 25px !important;
  }
}

/// Breadcrumb

.breadcrumb {
  margin-top: 25px;

  .breadcrumb-item {
    a {
      color: $darkgrey;
      text-decoration: none;
    }
  }

  .active {
    color: $primaryColor;
  }
}

//////////////////////////////PROJECT TRACKING
.grey-text {
  color: #7a7575;
}

.project-tracking {
  width: 100%;
  max-width: 460px;
  margin-top: 30px;

  hr {
    margin-top: 4px;
  }

  .proj-summary {
    background: #f8f8f8;
    box-shadow: 4px 4px 4px 0px #a3a3a333;
    padding: 20px;

    .prop {
      width: 84px;
    }

    .project-desc {
      p {
        font-size: 14px;
        line-height: 2;
        color: #7a7575;

        img {
          height: 25px;
        }
      }
    }

    .track-cta {
      button {
        background: $primaryColor;
        width: 100%;
        max-width: 190px;
        border-radius: 8px;
        padding: 10px;
        border: none;
        color: $white;
        text-transform: uppercase;

        img {
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }

  .tracking-stepper {
    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
      color: $primaryColor !important;

      circle {
        background-color: $primaryColor !important;
      }
    }

    .css-14yr603-MuiStepContent-root {
      border-left: 1px dotted #ff4949;
    }

    .css-1hv8oq8-MuiStepLabel-label.Mui-active {
      font-weight: normal !important;
    }
  }
}

////////////////SETTINGS
.settings {
  padding: 10px 15%;

  a {
    text-decoration: none !important;
  }

  .card {
    box-shadow: 0px 1px 4px 0px #00000040;
    width: 100% !important;
    margin: 10px;
    border-radius: 15px;
    background-color: #fafafa;
    cursor: pointer;

    .cardIcon {
      height: 46px;
    }

    .caret {
      color: #333333;
    }
  }

  .logout-card {
    background: #ff6262;

    .caret,
    p {
      color: #ffffff !important;
    }
  }
}

.settings-dialog {
  margin-top: 10%;

  .icon {
    height: 23px;
  }

  .chat {
    height: 27px;
  }

  .logout-m {
    padding: 25px;

    .img-pop {
      background: #eeeeee;
      border-radius: 60px;
      width: 101px;
      padding: 20px;
      height: 101px;

      img {
        background: #ff6262;
        width: 60px;
        border-radius: 38px;
        padding: 5px;
      }
    }

    button {
      width: 100%;
      margin: 5px auto;
      padding: 15px;
      border-radius: 10px;
      border: 0;
      box-shadow: 4px 4px 4px 0px #76767640;
    }

    .yes {
      background: #ff6262;
      color: #ffffff;
    }

    .no {
      background: #e5e5e5;
    }
  }

  .language {
    .modal-header {
      background: #f5f5f5;

      .close-btn {
        border: 2px solid #fa4c2f;
        color: #fa4c2f;
        width: 87px;
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px #afa6a640;
        padding: 10px 20px;
        font-weight: bold;
      }
    }

    .modal-body {
      padding: 20px;

      p {
        border-bottom: 0.5px solid #e5e5e5;
        text-align: left;
      }
    }
  }
}

/////////SIGN UP POPUP//////////
.signup {
  top: 12.5% !important;

  .signup-popup {
    .modal-content {
      max-width: 450px !important;
    }
  }

  input,
  label {
    border: 1px solid #7a7575;
  }

  span {
    border-top: 1px solid #7a7575;
    border-right: 1px solid #7a7575;
    border-bottom: 1px solid #7a7575;
  }
}

/////////////////////////MY INFO
.my-info,
.change-pass {
  width: 60%;

  .change-pass-form {
    input {
      box-shadow: 4px 4px 4px 0px #a3a3a333 !important;
      border: px solid #8c8c8c !important;
    }
  }

  .form-box {
    background: #fbfbfb;
    box-shadow: 0px 1px 4px 0px #00000040;
    padding: 10px 30px 40px;
    margin: 10px auto;

    .broker-img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100px;
      margin-bottom: 30px;
    }

    .edit-pic {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 32.5px;
      position: relative;
      top: 100px;
      left: 30px;
    }

    button {
      width: 100% !important;
    }
  }
}

/////////////////////////PROJECT DEALS
.project-deals {
  thead {
    background: #e8e8e8;
    box-shadow: 0px 1px 4px 0px #00000040;

    th {
      border-bottom: none;
      vertical-align: middle;
      padding: 10px;
    }
  }

  tbody {
    font-size: 14px;

    td {
      p {
        line-height: normal;
        margin-bottom: 0;
        position: relative;
        top: 10px;

        .broker-name {
          position: relative;
          top: -7px;
        }

        .rating {
          position: relative;
          left: 48px;
          top: -25px;
          font-size: 10px;
          color: white;
          background: #1b8989;
          padding: 0.5px 4px;
          border-radius: 1px;
        }
      }
    }

    td,
    th {
      border-bottom: 1.5px solid #eaeaea;
      vertical-align: middle;
    }

    .proj-img {
      width: 60px;
    }

    .broker-img {
      width: 45px;
    }

    .view-btn {
      background-color: $primaryColor;
      color: $white;
      padding: 8px;
      border-radius: 5px;
      border: none;
      width: 82px;
      font-size: 14px;
    }

    .ongoing-btn {
      border: solid 2px $primaryColor;
      border-radius: 5px;
      padding: 8px;
      background: $white;
      width: 135px;
      font-size: 14px;
    }

    .cancelled-btn {
      border: 2px solid #ff6262;
      border-radius: 5px;
      padding: 8px;
      background: $white;
      width: 135px;
      color: #ff6262;
      font-size: 14px;
    }
  }
}

/////////////////////////PROJECT DEALS & My Meetings
.project-deals,
.mymeetings {
  .meet-head {
    background-color: #fbfbfb;
    box-shadow: 0px 1px 4px 0px #00000040;
    border-bottom: solid 5px $primaryColor;
    margin-bottom: 5px;

    h4 {
      margin-bottom: 0;
      line-height: 3;
    }

    .book-btn {
      background-color: $primaryColor;
      color: $white;
    }

    .announce-btn {
      background-color: #e9e9e9;
    }

    .book-btn,
    .announce-btn {
      border: 0;
      padding: 10px;
      min-width: 155px;
      border-radius: 3px;
      margin-right: 10px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

////////////////NOTIFICATION
.notification-main {
  width: 650px !important;
  width: 100%;
  background-color: $white;
  border-radius: 10px;

  .notify-head {
    padding: 25px 5px;
    background: #f5f5f5;
    border-radius: 5px 5px 0px 0px;

    h5 {
      margin: auto;
      color: #474747;
      font-size: 22px;
      line-height: 2.1;
    }

    .close-btn {
      border: 2px solid #fa4c2f;
      color: #fa4c2f;
    }

    .back-btn {
      border: 2px solid $primaryColor;
      color: $primaryColor;
    }

    .close-btn,
    .back-btn {
      width: 87px;
      border-radius: 20px;
      box-shadow: 0px 4px 4px 0px #afa6a640;
      padding: 10px 20px;
      font-weight: bold;
    }
  }

  .row {
    padding-top: 15px;
    padding-bottom: 15px;

    p {
      margin-bottom: 0;
    }

    .broker-details,
    .title-p {
      font-weight: 500;
      line-height: 24px;
    }

    .text-p {
      color: #7a7575;
    }

    .notify-detail {
      .notification-num {
        text-align: center;
        font-size: 18px;
        line-height: 21px;
        background-color: $primaryColor;
        width: 30px;
        float: right;
        border-radius: 15px;
        padding: 3px;
        color: $white;
      }

      .notification-time {
        font-family: Calibri;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.09em;
        text-align: right;
        color: #8c8c8c;
      }

      .latest-notification {
        background-color: $primaryColor;
        width: 8px;
        height: 8px;
        border-radius: 15px;
        float: right;
        margin-top: 10px;
        margin-right: 2px;
      }
    }

    .meet-icon {
      width: 92px;
    }

    .sub-icon {
      width: 84px;
    }

    .broker-img {
      width: 45.8px;
    }

    .notify-icon {
      width: 35px;
    }
  }

  hr {
    border: 1px solid #c5c5c5;
    margin: 10px auto 20px auto;
  }

  .broker-img {
    position: relative;
    top: 35px;
    left: -25px;
  }
}

.sub-notification {
  max-height: 645px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

///////////////////my meetings
.mymeetings,
.broker_leads {
  .broker-img {
    width: 35px;
    position: relative;
    top: 35px;
    left: -25px;
  }

  .meet-icon {
    width: 75px;
  }

  .meeting-list {
    box-shadow: 0px 1px 4px 0px #00000040;
    height: 600px;
    overflow-y: scroll;

    .row {
      border: #eaeaea solid 1.5px;
      padding: 10px;

      p {
        margin-bottom: 5px;
      }
    }
  }

  .chat-list {
    box-shadow: 0px 1px 4px 0px #00000040;
    height: 600px;

    // overflow-y: scroll;
    .doc-verify-img {
      width: 16px;
    }

    .doc-submitted {
      width: 21px;
    }

    p {
      line-height: 5;
    }

    .more-icon {
      width: 32px;
      margin-top: 35px;
    }

    .detail-p {
      color: $primaryColor !important;
      letter-spacing: 1px;
      line-height: 2.8;
    }

    .in-out-msg {
      height: calc(100% - 299px);
      overflow-y: scroll;

      p {
        line-height: 1;
        font-size: 16px;
        margin-bottom: 0 !important;
      }

      img {
        width: 35px;
      }

      .received-msg,
      .sent-msg {
        max-width: 420px;
        min-width: 100px;
        height: fit-content;
        padding: 10px;
        margin-left: 50px;
        margin-top: -15px;
        box-shadow: 0px 1px 4px 0px #00000040;

        p {
          line-height: 1.5;
          font-size: 14px !important;
        }

        .time {
          float: right;
          color: #646363;
          font-size: 12px;
          margin-top: 10px;
        }
      }

      .received-msg {
        background-color: #e5e5e5;
        float: left;

        &::before {
          content: "";
          position: relative;
          top: -1.8px;
          right: 0px;
          left: -20px;
          width: 0px;
          height: 0px;
          border: 10px solid #e5e5e5;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }

      .sent-msg {
        background-color: #f6f6f6;
        float: right !important;

        &::after {
          content: "";
          position: relative;
          top: -22px;
          right: 0;
          left: 80px;
          width: 0px;
          height: 0px;
          border: 10px solid #f6f6f6;
          border-right-color: transparent;
          border-bottom-color: transparent;
        }
      }
    }

    .book-btn {
      background-color: $primaryColor;
      color: $white;
      padding: 10px 20px;
      border-radius: 50px;
      border: none;
    }

    .type-msg-box {
      height: 73px;
      box-shadow: 0px 1px 4px 0px #00000040;
      border-width: 1px, 1px, 0px, 0px;
      border-style: solid;
      border-color: #ededed;
      position: relative;

      // top: 302px;
      textarea {
        resize: none;
        background: #f6f6f6;
        margin-top: 5px;
        font-size: 12px;
        height: 58px !important;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2509803922);
      }

      .send-btn {
        width: 50px;
        margin-top: 10px;
      }
    }
  }

  .box-shdw {
    box-shadow: 0px 1px 4px 0px #00000040;
    padding: 20px;
  }
}

.box-shdw {
  box-shadow: 0px 1px 4px 0px #00000040;
  padding: 20px;
}

///////////////white-nav////
.white-nav {
  background-color: #ffffff !important;
}

//////selectBroker
.selectbroker {
  width: 45%;
  margin-left: auto;
  margin-right: auto;

  .form-select,
  .form-control {
    height: 48px !important;
    color: #6c757d;
  }

  .search-btn {
    height: 48px !important;
    line-height: 1.5;
    width: 100%;

    img {
      width: 20px !important;
    }
  }

  .pageHeader {
    background-color: $lightgrey;
    padding: 30px;

    .col-6,
    .col-5,
    .col-1 {
      padding: 3px !important;
    }
  }

  .selection-list {
    margin-top: 30px;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;

    .row {
      margin-bottom: 15px;
    }

    .lh-4 {
      line-height: 4;
    }

    margin-top: 30px;

    .brk-img {
      width: 64px;
    }

    .star {
      width: 19px;
      position: relative;
      left: -25px;
      top: 25px;
    }

    p {
      margin-bottom: 0 !important;
    }

    button {
      background-color: #e5e5e5;
      border-radius: 25px;
      padding: 10px;
      width: 115px;
    }
  }
}

.my-info,
.book-now {
  input,
  select,
  textarea {
    box-shadow: 4px 4px 4px 0px #a3a3a333;
    border: 1px solid #7a7575;
  }
}

.input-group {
  input#phonenum {
    border-radius: 0.25rem !important;
  }

  .PhoneInput {
    // max-width: 60px;
    border-radius: 0.25rem !important;
    margin-right: 10px;
    box-shadow: 4px 4px 4px 0px #a3a3a333;
    border: 1px solid #7a7575;
  }
}

/////book-now
.book-now {
  .stepper-box {
    background: #fbfbfb;
  }

  .red {
    color: #ff4949;
  }

  .mt-80px {
    margin-top: 80px;
  }

  .form-box {
    .book-now-info {
      input {
        box-shadow: 4px 4px 4px 0px #a3a3a333;
      }

      textarea,
      select,
      button {
        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .book-now-doc {
      .upload {
        border: 2px dotted gray;
        border-radius: 10px;
        // position: relative;
        top: 35%;
        height: 200px;

        label {
          cursor: pointer;
          color: gray;
          font-size: 14px !important;

          img {
            width: 55px;
            margin-top: 70px;
          }

          // span {
          //     top: -5px;
          //     position: relative;
          // }
        }

        input {
          display: none;
        }
      }
    }
  }
}

.waiting-screen {
  .wait-img {
    width: 100%;
    max-width: 465px;
  }

  img,
  button {
    display: block;
    margin: 10px auto;
  }

  .btn {
    width: 635px;
  }
}

// projDetails
.projDetails {
  margin-top: 30px;

  .project-img {
    width: 100% !important;
  }

  .img1 {
    height: 96.8%;
    z-index: 1;
    filter: brightness(0.5);
  }

  .view-more-txt {
    position: absolute;
    bottom: 45%;
    left: 35%;
    color: $white;
  }

  // .overlay-img {
  //     z-index: 2;
  //     position: absolute;
  //     left: 0%;
  // }

  // .overlay {
  //     position: absolute;
  //     bottom: 0;
  //     background: rgb(0, 0, 0);
  //     background: rgba(0, 0, 0, 0.5);
  //     /* Black see-through */
  //     color: #f1f1f1;
  //     width: 100%;
  //     opacity: 0;
  //     color: white;
  //     padding: 20px;
  //     text-align: center;
  // }

  hr {
    color: #e5e5e5;
    height: 1.5px;
  }

  .mainDetails {
    text-align: justify;

    .viewmore {
      color: $primaryColor !important;
      text-decoration: none;
    }
  }

  .download-card {
    height: 70px;
    margin-left: auto;
    border: solid 2px $primaryColor;
    position: relative;
    top: 15%;
    padding: 20px;
    font-size: 16px;
    color: $primaryColor;

    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  .small {
    color: #7a7575;
    font-weight: normal !important;
    font-family: Poppins;
    font-size: 18px;
  }

  .more-carousel {
    margin-top: 20px;

    .card {
      width: 100%;
      box-shadow: (4px 4px 4px rgba(163, 163, 163, 0.2));
      margin-bottom: 20px;
      background-color: $lightgrey;

      .card-body {
        .card-title {
          color: $primaryColor !important;
        }

        .bottom-flex {
          p {
            font-size: 12px;
            margin-bottom: 0;
          }

          .bed,
          .area {
            width: 23px;
            height: 23px;
          }
        }

        p.strikedoff {
          margin-left: 25px;
          text-decoration: line-through;
          color: #ababab;
        }

        p.ms-1 {
          line-height: 2.2;
          color: #212529 !important;
        }

        hr {
          margin: 10px;
        }

        button {
          position: absolute;
          font-size: 12px;
          right: 0;
          border: 0;
          padding: 7px 10px;
          background: $primaryColor;
          color: $white;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }
    }
  }
}

.projUnits {
  .rounded-tag {
    position: relative;
    right: 0;
    border: 0;
    padding: 7px 10px;
    background: $primaryColor;
    color: $white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  h4 {
    font-size: 22px !important;
  }

  h5 {
    color: $darkgrey;
    font-size: 18px !important;
    font-weight: 400;
  }

  hr {
    color: #ccc5c5;
    border: 1px !important;
  }

  li {
    line-height: 2.2;
  }

  .chat-card {
    position: fixed;
    z-index: 1;
    width: 28%;

    .start-now {
      padding: 7px 30px;
      color: $white;
      border-radius: 50px;
      background-color: $primaryColor;
      border: 0 !important;
    }
  }
}

// /////////////////////GALLERY//////////////////////
.gallery {
  .ind-btn {
    width: 100px !important;
  }

  .carousel-indicators {
    opacity: 1;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    bottom: -50px;
  }

  .carousel-caption {
    position: absolute;
    bottom: 1.25rem;
    left: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
    width: 100% !important;
    background: #00000099;

    p {
      margin-bottom: 0 !important;
    }
  }
}

// ///////////////////////////////////INDEXS/////////////////////////////
@import url("https://fonts.googleapis.com/css2?family=Baloo+Chettan+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap");

.navigation-items {
  // position: fixed !important;
  // width: 100%;
  // margin-top: -50px;
  // z-index: 999;
  .top-nav {
    background: white;
    border-bottom: 1px solid #23437033;

    p {
      font-size: 14px;
      font-weight: bold;
      color: #30383f;
      text-align: center;
      margin-bottom: 0 !important;
      padding: 6px;
    }
  }

  .indexs-nav {
    background-color: #f1f3f6;
    border-bottom: 1px solid #2343701a;
    height: 62px !important;
    font-family: "Yantramanav", sans-serif;

    ul {
      li {
        transition: 0.4s ease-in-out;

        .nav-link {
          text-transform: initial !important;
          color: #30383f !important;
          font-weight: normal !important;

          &:hover {
            color: #234370;
          }
        }
      }
    }
  }
}

////////////////////footer///////
.indexs-footer {
  background-color: #30383f;
  color: #ffffff;
  font-size: 16px;
  padding: 30px;
  text-align: center;
  margin-top: 60px;

  h5 {
    text-decoration: overline;
  }

  ul {
    li.list-group-item {
      background-color: transparent !important;
      border: none !important;

      a {
        color: #ffffff !important;
        text-decoration: none !important;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// /////////////////////////COMMON CSS////////////////////////
.indexs-h5 {
  color: #234370;
  font-weight: bold;
}

.indexs-h5,
.indexs-h4 {
  font-family: "Baloo Chettan 2", sans-serif;
  letter-spacing: 2px;
}

.indexs-h4 {
  font-weight: 300;
  text-transform: uppercase !important;
}

.indexs-p {
  font-size: 16px;
  font-family: "Yantramanav", sans-serif;
  margin-bottom: 0 !important;
  line-height: 1.4;
  color: #30383f;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

// ///////////////////////////BANNER/////////////////////
.indexs-banner {
  font-family: "Baloo Chettan 2", sans-serif;

  p {
    font-size: 17px;
    margin-bottom: 60px;
    font-weight: 300;
  }

  .banner-btn {
    color: #212529 !important;
    position: relative;
    text-decoration: none !important;

    &::before {
      background-color: rgba(88, 118, 160, 0.5);
      content: ".";
      height: 12px;
      overflow: hidden;
      position: absolute;
      transition: 0.2s ease-in-out;
      width: 48px;
      color: transparent;
      z-index: -1;
      margin-left: -20px;
      margin-top: 15px;
    }

    &:hover {
      color: #ffffff !important;

      &::before {
        height: 100%;
        width: 100%;
        padding: 8px 10px;
        margin-top: 1px;
        margin-left: 0px;
        background-color: #234370;
        border: 1px solid rgba(88, 118, 160, 0.2);
        border-radius: 2px;
      }
    }
  }
}

// ////////////////////////////HOME OTHERS///////////////////////////////////
.indexs-home {
  background-image: url(./component/images/indexs/it-solutions.jpg);
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;

  .in-bg-color {
    background-color: rgba(35, 36, 41, 0.91);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
    --bs-gutter-x: 1.5rem;
    padding: 5% 10%;

    .heading-row {
      h5 {
        color: #a6c6c6 !important;
      }

      .indexs-p {
        font-weight: normal;
        margin: 10px 0;
        color: #ffffff;
      }
    }

    .cards-row {
      .card {
        background-color: #a6c6c6;
        border-radius: 3px;
        border: 1px solid #a6c6c6;
        transition: 0.6s ease-in-out;
        position: relative;
        padding: 20px;
        cursor: pointer;

        .card-img {
          width: 58px;
          margin-left: auto;
        }

        .dark-img {
          display: block;
        }

        .light-img {
          display: none !important;
        }

        .card-body {
          p,
          h6 {
            color: #232429;
            letter-spacing: 1px;
          }

          h6 {
            font-weight: bold;
            font-family: "Baloo Chettan 2", cursive;
          }

          .btn {
            font-size: 22px;
            position: absolute;
            right: 25px;
            margin: 0 !important;
            bottom: -15px;
            line-height: 1;
            padding: 5px 10px !important;
            border: solid #a6c6c6;
            color: #a6c6c6;
            border-radius: 25px;
            background: #232429;
          }
        }

        &:hover {
          background-color: #232429;
          transition: 0.6s ease-in-out;

          .dark-img {
            display: none !important;
          }

          .light-img {
            display: block !important;
          }

          .card-body {
            h6,
            p {
              color: #a6c6c6 !important;
            }

            .btn {
              color: #232429;
              background: #a6c6c6;
              transition: 0.6s ease-in-out;
            }
          }
        }
      }
    }
  }
}

// ////////////////////////////
.propos {
  .card-row {
    width: 62%;
    margin: auto;

    .card {
      background: #5876a0;
      color: #ffffff;
      height: 370px;
      width: 242px;
      margin-top: 10px;
      margin-bottom: 10px;

      .card-header {
        background-color: transparent;
        border-bottom: 0;
        height: 250px !important;

        .card-img {
          width: 200px;
          height: 200px;
          display: block;
          margin: 15px auto;
          transition: all 0.3s;
        }

        p {
          display: none;
          transition: all 0.3s;
        }
      }

      p {
        font-family: "Baloo Chettan 2", cursive;
        font-size: 16px;
        line-height: 1.4rem;
      }

      &:hover {
        .card-header {
          display: flex;

          .card-img {
            transform: rotate(-360deg) scale(0.4);
            position: relative;
            left: -20px !important;
          }

          p {
            display: block;
            position: relative;
            right: 50%;
            top: 44%;
            font-size: 18px;
          }
        }
      }
    }
  }
}

////////////////////////////////////
.indexs-philosophie {
  img {
    width: 100%;
    margin-top: 5%;
    max-width: 555px;
    margin-left: auto;
    display: block;
  }
}

///////////////////////////////////////
.indexs-prestation {
  .main-overlay {
    position: relative;
    width: 100%;

    .img-header {
      .indexs-h5 {
        color: #a6c6c6;
        text-align: center;
        margin-bottom: 0 !important;
        padding: 7px;
        font-size: 16px !important;
      }

      background-color: #30383f;
      border-radius: 3px;
      height: 40px;
      left: 10%;
      top: -20px;
      z-index: 99;
      transition: 0.4s ease-in-out;
      width: 80%;
      position: absolute;
    }

    .img {
      display: block;
      width: 100%;
      height: auto;
    }

    .overlay-img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(48, 56, 63, 0.9);
      overflow: hidden;
      width: 100%;
      height: 0;
      transition: 0.5s ease;
    }

    .overlay-img1 {
      display: none !important;
    }

    &:hover {
      .overlay-img {
        height: 100%;
      }

      .img-header {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        transition: 0.3s ease;
        z-index: 99;
      }
    }

    .overlay-text {
      .indexs-p {
        color: white;
      }

      position: absolute;
      -webkit-transform: translate(-10%, -50%);
      -ms-transform: translate(-10%, -50%);
      text-align: center;
      top: 50%;
      left: 15%;
      transform: translate(-10%, -50%);
    }
  }
}

.parent-container {
  display: flex;
  width: 409px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .step-container {
    text-align: center;
    margin-right: auto;
    margin-left: auto;

    .step {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      padding: 15px 25px;
      border: 2px solid #87cd0f;
      color: #474747;
      font-weight: 500;
    }

    .step-number {
      margin-right: auto;
      margin-left: auto;
    }

    &.active {
      .step {
        border: 2px solid #f9aa25;
      }
    }
  }

  .separator {
    min-height: 1px;
    border-top: 2px dotted grey;
    width: 150px;
    margin-top: 25px;
  }
}

.phone-input {
  input {
    border: none !important;

    &:active {
      border: none !important;
      outline: none !important;
    }
  }
}

////////////// BROKER DASHBOARD /////////////////
.header-bg {
  background: #f8f8f8;

  li {
    a {
      margin-left: 35px !important;
      padding: 15px;

      span {
        color: #616161;
        text-transform: uppercase;
      }

      img {
        height: 22px;
      }
    }
  }
}

.active-nav {
  background-color: $primaryColor !important;
  width: 100%;

  span {
    color: $white !important;
  }
}

.main-dashboard {
  .story-img {
    width: 122px;
    display: block;
    margin: 2px auto;
    position: relative;
    top: -40px;
  }

  .story-from {
    width: 78px;
    display: block;
    margin: 2px auto;
    position: relative;
    top: -140px;
  }

  .label-p {
    position: relative;
    top: -120px;
  }

  .number-p {
    position: relative;
    top: 15px;
    right: -75px;
    background: #1b181880;
    width: 25px;
    border-radius: 20px;
    color: white;
    z-index: 999;
  }

  .card {
    .bed,
    .area {
      height: 17.26px;
    }

    .card-header {
      background: #7a7575;
      color: $white;
    }

    .list-group-item {
      border-bottom: 0 !important;

      label {
        color: #7a7575;
        font-size: 10px;
      }
    }

    .list-group-horizontal {
      .list-group-item {
        border: 0 !important;
      }

      .dashed-bg {
        line-height: 2;
        width: 50px !important;
        border-radius: 30px;
        height: 50px !important;
        text-align: center;
        margin: auto !important;
      }

      .bg-1 {
        background: #caac59;
      }

      .bg-2 {
        background: #dbc483;
      }

      .li-width {
        width: 68%;
      }

      .li-width2 {
        width: 78%;
      }
    }
  }

  .boost-box {
    .boost {
      width: 17px;
    }

    h5 {
      line-height: 1.35;
      color: $primaryColor;

      .primary-bg {
        background: $primaryColor;
        color: $white;
      }
    }

    .li-pd {
      li {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
}

///////PRIMARYPROJECTS////
.primary-project-main {
  .section-1 {
    background-color: #fbfbfb;
    box-shadow: 0px 1px 4px 0px #00000040;
    padding: 20px;
  }

  h4 {
    margin-top: 12px;
  }
}

// /////////////////SECONDARY PROJ//////////////
.secondary-proj {
  .ann-span {
    line-height: 2.5;
    font-size: 22px;
  }

  .filter {
    width: 52px !important;
    height: 50px;
    margin-top: 5px;
    margin-left: 30px;
    background-color: #f3f3f3;
    border-radius: 40px;
    padding: 15px;
  }

  .secondary {
    .card {
      border: none !important;
    }

    .user-img {
      width: 60px !important;
    }

    .card-text {
      font-size: 12px;
      margin-top: 5px;
    }

    .card-body {
      .d-flex {
        p {
          font-size: 12px;
          margin-bottom: 0;
          line-height: 2.3;
        }

        .bed,
        .area {
          width: 23px;
          height: 23px;
        }
      }
    }
  }
}

////////////////////COMMUNITY//////////////
.community {
  img {
    width: 100%;
    position: relative;
  }

  .overlay {
    position: relative;
    bottom: 70px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  .india-row {
    margin-top: -50px;
  }

  h6 {
    text-transform: uppercase;
    text-align: center;
  }

  .premium {
    width: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

//////////////////BROKER LEADS//////////////////////
.broker_leads {
  .filter-icon {
    width: 30px;
    margin-top: 10px;
  }

  .main-head {
    border-bottom: solid 5px $primaryColor;
  }

  .broker-img {
    width: 35px;
    position: relative;
    top: -25px;
    left: 40px;
  }

  .chats-p {
    margin-top: -20px;
    margin-bottom: 30px;
  }

  .chat-list {
    p {
      line-height: 1.5 !important;
    }

    .in-out-msg {
      height: calc(100% - 284px);
      overflow-y: scroll;
    }
  }

  .brokerage-btn,
  .amt-btn {
    position: relative;
    top: 22px;
    left: 35px;
    border-radius: 30px 0 50px 30px;
    border: 0px;
    background: #dbc483;
    color: #ffffff;
    font-weight: 300;
    padding: 10px 25px;
    font-size: 12px;
  }

  .amt-btn {
    top: -5px;
    left: 154.5px;
    position: relative;
    border-radius: 10px 0 0 10px;
  }

  .meeting-list {
    .row {
      padding: 10px;
      padding-top: 1rem;
      border-bottom: 0px;
      height: 120px !important;

      p {
        margin-bottom: 1px;
        font-size: 15px;
      }

      .rounded-num {
        background-color: $primaryColor;
        color: $white;
        padding: 0px 7px;
        border-radius: 20px;
        font-family: Verdana;
      }

      .meet-icon {
        margin-top: 10px;
      }

      .sup-img {
        float: right;
        background-color: #1b8989;
        padding: 15px;
        border-radius: 50px;

        .support-icon {
          width: 25px;
        }
      }

      .sup-span {
        float: right !important;
        margin-top: -20px;
      }
    }
  }
}

////////////////////LEADS SUPPORT//////////////////////////////
.lead-support {
  .chat-list {
    height: 450px;

    .in-out-msg {
      height: calc(100% - 175px);
      overflow-y: scroll;
    }
  }
}

///////////////////PAYMENTS//////////////////////////////
.payments {
  .orange-text {
    color: #f2b200;
  }

  .green-text {
    color: #14ae5c;
  }

  thead {
    th {
      text-align: center;
    }
  }

  tbody {
    td {
      text-align: center;

      p {
        .broker-name {
          top: 0;
          margin-left: 10px;
        }

        .user-img {
          position: relative;
          width: 25px;
          top: -16px;
          left: -2px;
        }
      }

      .progress {
        width: 135px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
        height: 10px;

        .progress-bar {
          background-color: #f2b200;
        }

        .pb-green {
          background-color: #14ae5c;
        }
      }

      .ongoing-btn,
      .o-caret {
        border-color: #f2b200;
        color: #f2b200;
      }

      .o-caret {
        a {
          color: #f2b200 !important;
          text-decoration: none;
        }
      }

      .cancelled-btn,
      .g-caret {
        border-color: #14ae5c;
        color: #14ae5c;
      }

      .o-caret,
      .g-caret {
        border: solid 1px;
        border-radius: 25px;
        padding: 5px 12px;
        font-size: 16px;
        background-color: #f8f8f8;
      }
    }
  }
}

//////////////////////////PAYMENT TRACKING///////////////////////
.track-pay {
  .tracking-btn {
    width: 65%;
    padding: 15px;
    background: $gold;
    color: $white;
    border-radius: 10px;
    border: 0px;
  }

  .track-p,
  .tracking-btn,
  .MuiBox-root.css-16r9pk3 {
    margin: 10px auto;
    display: block;
  }

  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: $primaryColor !important;

    circle {
      background-color: $primaryColor !important;
    }
  }

  .css-8t49rw-MuiStepConnector-line,
  .css-14yr603-MuiStepContent-root {
    border-left: 1px dotted #bdbdbd;
  }

  .css-1hv8oq8-MuiStepLabel-label,
  .css-ahj2mt-MuiTypography-root {
    font-weight: normal;
    font-family: "Poppins", sans-serif;
  }
}

////////////////////////BROKER INFO////////////////////////
.broker-info {
  .optionListContainer {
    left: 0;
    margin-top: 8px;
  }

  .searchWrapper {
    border: 0px;
  }
}

//////////////////////////BROKER ACCOUNT////////////////////
.broker-acc {
  .small {
    font-size: 12px;
  }

  .gradient-text {
    background: -webkit-linear-gradient(
      230.37deg,
      #1b8989 15.88%,
      #dbc483 84.02%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  button {
    width: 100px;
    border-radius: 25px;
    border: 0px;
    color: $white;
    font-weight: 300;
    padding: 8px;
    font-size: 12px;
  }
  .primary-btn {
    background: $primaryColor;
  }
  .gold-btn {
    background: $gold;
  }
}

.pointer {
  cursor: pointer;
}

// Need Help
.PhoneInputInput {
  outline: none;
  border: none;
}

.linkText {
  text-decoration: none;
  color: #232429;
  &:hover {
    color: #1b8989;
  }
}

.pos-value {
  top: 17px;
  right: 20px;
  &:hover {
    color: #1b8989;
  }
}
